import React from 'react';
import { EXPERIMENT_USE_RICOS } from '@wix/communities-blog-experiments';
import withExperiment from '../../../common/hoc/with-experiment';
import { useNextRCE } from '../../services/config';
import RichContentViewer from './rich-content-viewer';
import RicosViewer from './ricos-viewer';

export default withExperiment({ isUseRicosExperimentEnabled: EXPERIMENT_USE_RICOS })(
  ({ isUseRicosExperimentEnabled, ...rest }) => {
    const Component = useNextRCE && isUseRicosExperimentEnabled ? RicosViewer : RichContentViewer;
    return <Component {...rest} />;
  }
);
